import { Box, Text, useComputedColorScheme } from '@mantine/core';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import { useMemo, useRef } from 'react';

import { Tooltip } from '@/components';
import { useDimensions, useTooltip } from '@/hooks';
import type { Color } from '@/types';
import { getColor, scaleSampleConcentration } from '@/utils';

interface Item {
  collection_date: string;
  color: Color;
  value: number;
  level: string;
  range: [number, number];
  unit: string;
}

interface Props {
  data: Item[];
}

const BAR_HEIGHT = 5;
const MARKER_HEIGHT = 20;
const POINT_RADIUS = 4;

export function SampleConcentration({ data }: Props) {
  const container = useRef<HTMLDivElement>(null);
  const { width } = useDimensions(container);
  const colorScheme = useComputedColorScheme();

  const referenceRangeMin = d3.min(data, item => item.range[0]) ?? 0;
  const referenceRangeMax = d3.max(data, item => item.range[1]) ?? 1000000;

  const borderline = width * (referenceRangeMin != null ? 0.3834 : 0.6917);
  const reference = width * (referenceRangeMin != null ? 0.2834 : 0.6417);

  const xScale = useMemo(
    () =>
      (item: Item): number =>
        scaleSampleConcentration(
          item.color,
          item.value,
          item.level,
          referenceRangeMin,
          referenceRangeMax,
          width,
        ),
    [data, width],
  );

  const { tooltip, show, hide } = useTooltip<Item>(null);

  return (
    <Box h="40px" pos="relative" ref={container}>
      <Tooltip position={tooltip.position}>
        {tooltip.data && (
          <>
            <Text size="sm" fw={700}>
              {[tooltip.data.value, tooltip.data.unit].join(
                tooltip.data.unit === 'th percentile' ? '' : ' ',
              )}
            </Text>
            <Text size="sm">
              {[tooltip.data.range.join(' - '), tooltip.data.unit].join(
                tooltip.data.unit === 'th percentile' ? '' : ' ',
              )}
            </Text>
            <Text size="sm">
              {dayjs(tooltip.data.collection_date).format('MMM YYYY')}
            </Text>
          </>
        )}
      </Tooltip>
      <svg width="100%" height="40px">
        <g width="100%" height="100%">
          <rect
            width="100%"
            height={BAR_HEIGHT}
            y={15}
            fill={colorScheme === 'light' ? '#dbdbdb' : '#121625'}
            stroke={colorScheme === 'light' ? '#999' : '#fff'}
            strokeWidth={1}
            rx={POINT_RADIUS}
          />

          <rect
            width={borderline}
            height={BAR_HEIGHT}
            y={15}
            x={
              referenceRangeMin != null
                ? width / 2 - borderline / 2
                : width - borderline
            }
            fill={colorScheme === 'light' ? '#f3f3f3' : '#1a1d2c'}
          />
          <rect
            width={reference}
            height={BAR_HEIGHT}
            y={15}
            x={
              referenceRangeMin != null
                ? width / 2 - reference / 2
                : width - reference
            }
            fill={colorScheme === 'light' ? '#fdfdff' : '#1f222f'}
          />
        </g>

        <g width="100%" height="100%">
          {data
            .filter(item => item.value !== 0)
            .map((item, i) => (
              <rect
                key={i}
                width={POINT_RADIUS * 2}
                height={MARKER_HEIGHT}
                fill={getColor(item.color)}
                x={width - xScale(item) - POINT_RADIUS}
                y={7}
                rx={POINT_RADIUS}
                onMouseMove={() => show(item, width - xScale(item), 15)}
                onMouseOut={hide}
              />
            ))}
        </g>
      </svg>
    </Box>
  );
}
