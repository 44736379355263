import axios from 'axios';

import type { Biofunction } from '@/types';
import { getPostHeaders } from '@/utils';

interface Params {
  id: string;
}

export const getBiofunctionDetails = async ({
  id,
}: Params): Promise<Biofunction> =>
  await axios
    .get(`/client/reports/biofunctions/${id}`, {
      headers: {
        ...getPostHeaders(),
      },
      params: {
        fields: [
          'history',
          'health_report_sample_concentrations',
          'general_description',
          'biofunction_name',
        ].join(','),
      },
    })
    .then(({ data }) => data.biofunction);
