import { Link, usePage } from '@inertiajs/react';
import { Button, Group, ScrollArea } from '@mantine/core';

import {
  client_reports_health_data_path,
  client_reports_sample_concentrations_path,
  // client_reports_biofunctions_path,
} from '@/routes';

const links = [
  {
    label: 'Health scores',
    href: client_reports_health_data_path(),
  },
  /*{
    label: 'Pathways',
    href: client_reports_biofunctions_path(),
  },*/
  {
    label: 'All biomarkers',
    href: client_reports_sample_concentrations_path(),
  },
];

export function Menu() {
  const { url } = usePage();

  return (
    <ScrollArea type="never" scrollbars="x">
      <Group gap="xs" wrap="nowrap">
        {links.map(link => (
          <Button
            key={link.href}
            size="sm"
            variant={url.includes(link.href) ? 'filled' : 'subtle'}
            color={url.includes(link.href) ? 'blue' : 'dimmed'}
            component={Link}
            href={link.href}
          >
            {link.label}
          </Button>
        ))}
      </Group>
    </ScrollArea>
  );
}
