const getPxRange = (color: string, height: number): number => {
  switch (color) {
    case 'green':
      return height * 0.2834;
    case 'yellow':
      return (height * 0.1) / 2;
    case 'red':
      return (height * 0.6166) / 2;
    default:
      return 0;
  }
};

const getPxLowerBound = (
  color: string,
  level: string,
  height: number,
): number => {
  switch (color) {
    case 'green':
      return height * 0.3583;
    case 'yellow':
      return height * (level === 'low' ? 0.3083 : 0.6417);
    case 'red':
      return level === 'low' ? 0 : height * 0.6917;
    default:
      return 0;
  }
};

const calculateScaledValue = (
  value: number,
  color: string,
  level: string,
  low: number,
  high: number,
): number => {
  const rangeError = (high - low) * 0.05;

  let rangeLow = 0;
  let rangeHigh = 1000000;

  if (color === 'green') {
    rangeLow = low;
    rangeHigh = high;
  } else if (color === 'yellow' && level === 'low') {
    rangeLow = low - rangeError;
    rangeHigh = low + rangeError;
  } else if (color === 'yellow' && level === 'high') {
    rangeLow = high - rangeError;
    rangeHigh = high + rangeError;
  } else if (color === 'red' && level === 'low') {
    rangeHigh = low - rangeError;
  } else if (color === 'red' && level === 'high') {
    rangeLow = high + rangeError;
  }

  return (value - rangeLow) / (rangeHigh - rangeLow);
};

export const scaleSampleConcentration = (
  color: string,
  value: number,
  level: string,
  rrLow: number,
  rrHigh: number,
  height: number,
): number => {
  const pxRange = getPxRange(color, height);
  const pxLowerBound = getPxLowerBound(color, level, height);
  const scaledValue = calculateScaledValue(value, color, level, rrLow, rrHigh);

  return height - (pxLowerBound + scaledValue * pxRange);
};
