import type { Measure } from '@/types';

import { filterNotEmpty } from './filterNotEmpty';

export const selectSampleConcentrationHistory = (
  sampleConcentrations: (Measure | null)[],
) =>
  sampleConcentrations.filter(filterNotEmpty).map(row => ({
    collection_date: row.collection_date,
    color: row.color,
    value: row.percentile ?? Number(row.value),
    level: row.level,
    unit: row.unit,
    range: row.range,
  }));
