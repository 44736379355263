import axios from 'axios';

import type { Credentials, Measure, SampleConcentration } from '@/types';
import { getPostHeaders } from '@/utils';

interface Params {
  reportId: number;
  mycoId?: string;
}
export const getBiomarkerDetail = async (
  id: number,
  { reportId, mycoId }: Params,
  { email, apiKey }: Credentials,
): Promise<{
  sample_concentration: SampleConcentration & {
    biomarker_table: (Measure | null)[];
  };
}> => {
  const { data } = await axios.get(
    `${window.location.origin}/provider/api/sample_concentrations/${id}`,
    {
      headers: {
        ...getPostHeaders(),
        Authorization: `Basic ${btoa(`${email}:${apiKey}`)}`,
      },
      params: {
        report_id: reportId,
        health_area_myco_id: mycoId,
        fields: [
          'measure_name',
          'percentile',
          'range',
          'delta',
          'collection_date',
          'value',
          'unit',
          'level',
          'color',
          'associated_health_areas',
          'score',
          'text_range',
          'normal_description',
          'nutrition_description',
          'biomarker_table',
        ].join(','),
      },
    },
  );

  return { sample_concentration: data.sample_concentration }; // Return as an object
};
