import {
  Box,
  Card,
  ColorSwatch,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getBiomarkerDetail } from '@/api/provider/getBiomarkerDetail';
import { SampleConcentrationHistory } from '@/charts';
import { useUser } from '@/hooks';
import { selectSampleConcentrationHistory } from '@/selectors';
import type { Provider } from '@/types';
import type { Color } from '@/types';
import { formatRange, formatValue } from '@/utils';

interface Props {
  id: number;
  reportId: number;
}

const getColor = (color: Color): string => {
  switch (color) {
    case 'green':
      return '#3FB6DC';
    case 'yellow':
      return '#FFC800';
    case 'red':
      return '#CF3616';
    default:
      return 'black';
  }
};

const getLabel = (color: Color): string => {
  switch (color) {
    case 'green':
      return 'In-range';
    case 'yellow':
      return 'Borderline';
    case 'red':
      return 'Out of range';
  }
};

export function BiomarkerDetails({ id, reportId }: Props) {
  const provider = useUser<Provider>();

  const {
    data: SampleConcentration,
    isLoading,
    isFetching,
    isSuccess,
  } = useQuery(
    ['SampleConcentration', id],
    async () =>
      await getBiomarkerDetail(
        id,
        { reportId },
        {
          email: provider.email,
          apiKey: provider.api_key,
        },
      ),
    {
      enabled: !!id,
      initialData: () => null,
    },
  );

  const data = SampleConcentration?.sample_concentration;
  const processedData = useMemo(
    () => selectSampleConcentrationHistory(data?.biomarker_table || []),
    [data?.biomarker_table],
  );

  if (isLoading || isFetching) {
    return (
      <Text ta="center" p="xl">
        Loading
      </Text>
    );
  }

  if (isSuccess && data) {
    return (
      <>
        <Title tt="capitalize" lineClamp={1} mb="md">
          {data.measure_name}
        </Title>

        <Card style={{ overflow: 'visible' }}>
          <Stack gap="md">
            <Group justify="space-between">
              <Box>
                <Text fw={700}>Your Result</Text>
                <Text>
                  {formatValue(data.value, data.unit, data.percentile)}
                </Text>
              </Box>
              <Box>
                <Text fw={700}>Ref Range</Text>
                <Text>
                  {formatRange(data.range, data.unit, data.percentile)}
                </Text>
              </Box>
            </Group>

            <Box h="200">
              <SampleConcentrationHistory data={processedData} />
            </Box>
            <Group gap="xs" justify="space-between">
              {['green', 'yellow', 'red'].map((color, i) => (
                <Group gap="xs" key={i}>
                  <ColorSwatch
                    size={8}
                    color={getColor(color)}
                    withShadow={false}
                  />
                  <Text size="sm">{getLabel(color)}</Text>
                </Group>
              ))}
            </Group>
            <Box>
              <Text fw={700}>Overview</Text>
              <Text>{data.normal_description}</Text>
            </Box>
            <Box>
              <Text fw={700}>How to improve</Text>
              <Text>{data.nutrition_description}</Text>
            </Box>
          </Stack>
        </Card>
      </>
    );
  }
}
