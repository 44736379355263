import axios from 'axios';

import type { Measure, SampleConcentration } from '@/types';
import { getPostHeaders } from '@/utils';

interface Params {
  reportId: number;
}
export const getBiomarkerDetail = async (
  id: number,
  { reportId }: Params,
): Promise<{
  sample_concentration: SampleConcentration & {
    biomarker_table: (Measure | null)[];
  };
}> => {
  const { data } = await axios.get(`/client/api/sample_concentrations/${id}`, {
    headers: {
      ...getPostHeaders(),
    },
    params: {
      report_id: reportId,
      fields: [
        'measure_name',
        'percentile',
        'range',
        'delta',
        'collection_date',
        'value',
        'unit',
        'level',
        'color',
        'associated_health_areas',
        'score',
        'text_range',
        'normal_description',
        'nutrition_description',
        'biomarker_table',
      ].join(','),
    },
  });
  return { sample_concentration: data.sample_concentration };
};
