import { Page } from '@/components';
import { type Client } from '@/types';

import { Form } from './_Form';

interface Props {
  client: Client;
}

export default function New({ client }: Props) {
  return (
    <Page title="Create Patient">
      <Form client={client} action="/provider/clients" />
    </Page>
  );
}
