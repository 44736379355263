import { Container, Grid, Stack } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Empty } from '@/components';
import { client_tests_index_path } from '@/routes';
import type { Biofunction } from '@/types';

import { Menu } from '../_Menu';
import { BiofunctionDetails } from './_Biofunction';
import { NavGroup } from './_NavGroup';

interface Props {
  biofunctions: Biofunction[];
  reportId?: number;
}

export default function Pathways({ biofunctions, reportId }: Props) {
  const { t } = useTranslation();
  const [selectedBiofunction, setSelectedBiofunction] = useState(0);

  return (
    <Container size="lg">
      <Stack gap="xl">
        <Menu />
        {biofunctions?.length > 0 ? (
          <Grid gutter="xl">
            <Grid.Col span={4}>
              <NavGroup
                biofunctions={biofunctions}
                value={selectedBiofunction}
                onSelect={index => setSelectedBiofunction(index)}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <BiofunctionDetails
                reportId={reportId}
                biofunction={biofunctions[selectedBiofunction]}
              />
            </Grid.Col>
          </Grid>
        ) : (
          <Empty
            headline={t('report.no_data.headline')}
            strapline={t('report.no_data.strapline')}
            href={client_tests_index_path()}
          />
        )}
      </Stack>
    </Container>
  );
}
