import { Box } from '@mantine/core';
import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';

import { useDimensions } from '@/hooks';

interface Props {
  data: Record<string, number>;
}

export function BiomarkerSummary({ data }: Props) {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const container = useRef<HTMLDivElement>(null);

  const { width, height } = useDimensions(container);
  useEffect(() => {
    if (!width || !height) return;

    const { green, yellow, red, total } = data;

    const barData = [
      { color: 'green', value: (green / total) * 100 },
      { color: 'yellow', value: (yellow / total) * 100 },
      { color: 'red', value: (red / total) * 100 },
    ];

    const svgWidth = width;
    const svgHeight = 20;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    svg
      .selectAll('rect')
      .data(barData)
      .enter()
      .append('rect')
      .attr('x', (d, i) => {
        return i === 0
          ? 0
          : d3.sum(barData.slice(0, i).map(d => (d.value / 100) * svgWidth));
      })
      .attr('y', 0)
      .attr('width', d => (d.value / 100) * svgWidth)
      .attr('height', svgHeight)
      .attr('fill', d => {
        switch (d.color) {
          case 'green':
            return '#3FB6DC';
          case 'yellow':
            return '#FFC800';
          case 'red':
            return '#CF3616';
          default:
            return '#000';
        }
      });
  }, [data, width, height]);

  return (
    <Box w="30%" h="20" ref={container}>
      <svg
        ref={svgRef}
        width={width}
        height={20}
        style={{ borderRadius: '5px', overflow: 'hidden' }}
      />
    </Box>
  );
}
