import { Box, Text, useComputedColorScheme } from '@mantine/core';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import { useMemo, useRef } from 'react';

import { Tooltip } from '@/components';
import { useDimensions, useTooltip } from '@/hooks';
import type { Color } from '@/types';
import { getColor, scaleSampleConcentration } from '@/utils';

interface Item {
  collection_date: string;
  color: Color;
  value: number;
  level: string;
  range: [number, number];
  unit: string;
}

interface Props {
  data: Item[];
}

const POINT_RADIUS = 8;

export function SampleConcentrationHistory({ data }: Props) {
  const container = useRef<HTMLDivElement>(null);

  const { width, height } = useDimensions(container);

  const colorSchme = useComputedColorScheme();

  const referenceRangeMin = d3.min(data, item => item.range[0]) ?? 0;
  const referenceRangeMax = d3.max(data, item => item.range[1]) ?? 1000000;

  const borderline = height * (referenceRangeMin != null ? 0.3834 : 0.6917);
  const reference = height * (referenceRangeMin != null ? 0.2834 : 0.6417);

  const xDomain = useMemo(() => data.map(item => item.collection_date), [data]);

  const xScale = useMemo(
    () => d3.scalePoint().domain(xDomain).range([0, width]).padding(0.5),
    [data, width],
  );

  const yScale = useMemo(
    () =>
      (item: Item): number =>
        scaleSampleConcentration(
          item.color,
          item.value,
          item.level,
          referenceRangeMin,
          referenceRangeMax,
          height,
        ),
    [data, height],
  );

  const line = d3
    .line<Item>()
    .x(item => xScale(item.collection_date) ?? 0)
    .y(item => yScale(item));

  const { tooltip, show, hide } = useTooltip<Item>(null);

  return (
    <Box w="100%" h="100%" pos="relative" ref={container}>
      <Tooltip position={tooltip.position}>
        {tooltip.data && (
          <>
            <Text size="sm" fw={700}>
              {[tooltip.data.value, tooltip.data.unit].join(
                tooltip.data.unit === 'th percentile' ? '' : ' ',
              )}
            </Text>
            <Text size="sm">
              {[tooltip.data.range.join(' - '), tooltip.data.unit].join(
                tooltip.data.unit === 'th percentile' ? '' : ' ',
              )}
            </Text>
            <Text size="sm">
              {dayjs(tooltip.data.collection_date).format('MMM YYYY')}
            </Text>
          </>
        )}
      </Tooltip>
      <svg width="100%" height="100%">
        <g width="100%" height="100%">
          <rect
            width="100%"
            height="100%"
            fill={colorSchme === 'light' ? '#e9efff' : '#121625'}
          />
          <rect
            width="100%"
            height={borderline}
            y={
              referenceRangeMin != null
                ? height / 2 - borderline / 2
                : height - borderline
            }
            fill={colorSchme === 'light' ? '#f5f7ff' : '#1a1d2c'}
          />
          <rect
            width="100%"
            height={reference}
            y={
              referenceRangeMin != null
                ? height / 2 - reference / 2
                : height - reference
            }
            fill={colorSchme === 'light' ? '#fdfdff' : '#1f222f'}
          />
        </g>
        <g width="100%" height="100%">
          <path
            fill="none"
            stroke="#d2d7e1"
            strokeWidth={2}
            d={line(data.filter(item => item.value !== 0))}
          />
        </g>
        <g width="100%" height="100%">
          {data
            .filter(item => item.value !== 0)
            .map((item, i) => (
              <circle
                key={i}
                r={POINT_RADIUS}
                fill={getColor(item.color)}
                cx={xScale(item.collection_date)}
                cy={yScale(item)}
                onMouseMove={() =>
                  show(
                    item,
                    xScale(item.collection_date),
                    yScale(item) - POINT_RADIUS,
                  )
                }
                onMouseOut={hide}
              />
            ))}
        </g>
      </svg>
    </Box>
  );
}
