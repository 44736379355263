import { Card, Group, Stack, Text, Title } from '@mantine/core';

import { BiomarkerSummary } from '@/charts';
import type { Biofunction } from '@/types';

import classes from './_NavGroup.module.css';

interface Props {
  biofunctions: Biofunction[];
  value: number;
  onSelect: (index: number) => void;
}

export function NavGroup({ onSelect, value, biofunctions }: Props) {
  return (
    <Stack gap="xs">
      <Title lineClamp={1} mb="xs">
        Pathways
      </Title>
      {biofunctions.map((biofunction, index) => (
        <Card
          key={index}
          onClick={() => {
            onSelect(index);
          }}
          className={classes.navLink}
          data-active={value === index}
          style={{ cursor: 'pointer' }}
        >
          <Group justify="space-between">
            <Text tt="capitalize" truncate w="60%">
              {biofunction.biofunction_name}
            </Text>
            <BiomarkerSummary data={biofunction.all_measure_counts} />
          </Group>
        </Card>
      ))}
    </Stack>
  );
}
