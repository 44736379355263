import { Grid } from '@mantine/core';
import { type ReactElement, type ReactNode, useState } from 'react';

import { Provider as Layout } from '@/layouts';
import type { Biofunction, Client } from '@/types';

import { SubHeader } from '../_SubHeader';
import { BiofunctionDetails } from './_Biofunction';
import { NavGroup } from './_NavGroup';

interface Props {
  biofunctions: Biofunction[];
  client: Client & {
    current_report?: {
      id: number;
    };
  };
}
function Pathways({ biofunctions, client }: Props) {
  const [selectedBiofunction, setSelectedBiofunction] = useState(0);
  return (
    <Grid gutter="xl">
      <Grid.Col span={4}>
        <NavGroup
          biofunctions={biofunctions}
          value={selectedBiofunction}
          onSelect={index => setSelectedBiofunction(index)}
        />
      </Grid.Col>
      <Grid.Col span={8}>
        <BiofunctionDetails
          reportId={client.current_report?.id}
          clientId={client.id}
          biofunction={biofunctions[selectedBiofunction]}
        />
      </Grid.Col>
    </Grid>
  );
}

Pathways.layout = (page: ReactNode & { props: Props }): ReactElement => (
  <Layout subHeader={<SubHeader client={page.props.client} />} page={page} />
);

export default Pathways;
