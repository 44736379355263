import { Page } from '@/components';
import { type Client } from '@/types';

import { Form } from './_Form';

interface Props {
  client: Client;
}

export default function Edit({ client }: Props) {
  return (
    <Page title="Update Patient">
      <Form client={client} action={`/provider/clients/${client.id}`} />
    </Page>
  );
}
